export default {
  leastDrawn: 'Least drawn',
  mostDrawn: 'Most drawn',
  leastPlayed: 'Least played',
  mostPlayed: 'Most played',
  drawFrom: 'Draw from',
  h: 'h', // short hours
  startingIn: 'Starting in',
  waitingForDrawnFrom: 'Waiting for drawn from',
  displayingDrawFrom: 'Displaying draw from',
  lotteryDraw: 'Lottery draw',
  eventId: 'Event ID',
  bettingShortcuts: 'Betting shortcuts',
  draw: 'Draw',
  next5Events: 'Next {value} Displaying',
  events: 'Events',
  schedule: 'Schedule',
  next: 'Next',
  event: 'Event',
  later: 'Later',
  statistics: 'Statistics',
  lastnRounds: 'Last {value} rounds',
  bettingOffer: 'Betting Offer',
  notDisplayed: 'Not displayed',
  latestResults: 'Latest results',
  drawnAt: 'Drawn at {value}',
  additionalBetting: 'Additional betting',
  drawSumEvenOdd: 'Draw sum Even or Odd',
  drawSumTotal: 'Draw sum total',
  drawSumRange: 'Draw sum range',
  waitingForDrawNo: 'Waiting for draw No.{value}',
  nextDraw: 'Next draw',
  waitingForDraw: 'Waiting for draw',
  drawCompleted: 'Draw completed',
  drawDisplayTemporarilyNotAvailable: 'Draw display temporarily not available',
  connectionError: 'Connection error!',
  reconnecting: 'Reconnecting...',
  number: 'Number',
  headerNumberDescriptionUp: 'Event',
  headerNumberDescriptionDown: 'Number',
};
